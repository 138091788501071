import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { camelToDashLine } from '@/utils/func'

Vue.use(VueRouter)
NProgress.configure({ showSpinner: false })

// const homeDomain = process.env.VUE_APP_HOME_URL
const appDomain = process.env.VUE_APP_APP_URL

const buildDocPages = () => {
  const tempItems = []
  const viewFiles = require.context(
    '/src/views/Doc/Pages',
    true,
    /\.vue$/,
    'lazy'
  )

  viewFiles.keys().forEach((fileName) => {
    const name = fileName.replace(/^\.\/(.*)\.\w+$/, '$1')

    const path = camelToDashLine(name)

    // 页面路由声明
    const pageItem = {
      path: `/docs/${path}`,
      name: name,
      component: () => import('@/views/Doc/Pages/' + name + '.vue')
    }

    tempItems.push(pageItem)
  })
  return tempItems
}

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   meta: {
  //     domain: homeDomain
  //   },
  //   component: () => import('@/views/Home.vue')
  // },

  {
    path: '/',
    name: 'DID',
    meta: {
      domain: appDomain
    },
    component: () => import('@/views/Registration.vue'),
    children: [
      {
        path: '/dapp',
        name: 'dapp',
        meta: {
          domain: appDomain
        }
      },
      {
        path: '/dapp/:suffix',
        name: 'dapp',
        meta: {
          domain: appDomain
        }
      },
      {
        path: '/batch',
        name: 'batch',
        meta: {
          domain: appDomain
        }
      },
      {
        path: '/names',
        name: 'names',
        meta: {
          domain: appDomain
        }
      },
      {
        path: '/derivatives',
        name: 'derivatives',
        meta: {
          domain: appDomain
        }
      }
    ]
  },

  {
    path: '/docs',
    name: 'doc',
    component: () => import('@/views/Doc/Container.vue'),
    children: [...buildDocPages()]
  },

  {
    path: '/arts',
    name: 'arts',
    component: () => import('@/views/Arts/Container.vue'),
    children: [
      {
        path: '/arts/details',
        name: 'ArtDetails',
        meta: {
          domain: appDomain
        },
        component: () => import('@/views/Arts/Pages/ArtDetails.vue')
      },
      {
        path: '/arts/show-center',
        name: 'ShowCenter',
        meta: {
          domain: appDomain
        },
        component: () => import('@/views/Arts/Pages/ShowCenter.vue')
      }
    ]
  },

  // {
  //   path: '/my-product',
  //   name: 'MyProduct',
  //   meta: {
  //     domain: appDomain
  //   },
  //   component: () => import('@/views/Sales/MyProduct.vue')
  // },

  // {
  //   path: '/didasset/sell-product/:id',
  //   name: 'SellProduct',
  //   meta: {
  //     domain: appDomain
  //   },
  //   component: () => import('@/views/Sales/SellProduct.vue')
  // },
  // {
  //   path: '/didasset/sell-product/',
  //   name: 'SellProduct',
  //   meta: {
  //     domain: appDomain
  //   },
  //   component: () => import('@/views/Sales/SellProduct.vue')
  // }
  // ]
  // },

  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

function scrollBehavior (to, from, savedPosition) {
  return {
    x: 0,
    y: 0
  }
}

// 路由守卫钩子
router.beforeEach((to, from, next) => {
  // 用户直接访问 app.diddao.io 有可能会渲染 diddao.io的页面

  // 开始顶部进度条的动画
  NProgress.start()

  Vue.prototype.$fromUrl = from.path
  console.log('Route from:', from, 'Route to:', to)

  // if (to.name === 'did' || to.name === 'DID') {
  //   Vue.prototype.$refAddr = to.query.recommender
  // }
  next()
})

router.afterEach(() => {
  // 结束顶部进度条的动画
  NProgress.done()
})

export default router
