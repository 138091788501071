import { utils, BigNumber, providers } from 'ethers'
import namehash from 'eth-ens-namehash'
import { Vue } from '@/antDesign'
import QRCode from 'qrcode'
import WalletConnectProvider from '@walletconnect/web3-provider'

const message = Vue.prototype.$message
/**
 * getDateString 获取指定日期的数天后或数天前的日期
 * @param {String} dateString 指定日期
 * @param {Number} AddDayCount 天数
 * @returns {String}
 */

// export const getDateString = (dateString, AddDayCount) => {
//   if (isMac | isiOS) {
//     dateString = dateString.replace(/-/g, '/')
//   }

//   var dd = new Date(dateString)

//   if (AddDayCount) {
//     dd.setDate(dd.getDate() + AddDayCount) // 获取AddDayCount天后的日期
//   }

//   var y = dd.getFullYear()
//   var m = dd.getMonth() + 1 // 获取当前月份的日期
//   var d = dd.getDate()
//   m = m < 10 ? '0' + m : m
//   d = d < 10 ? '0' + d : d
//   return y + '-' + m + '-' + d
// }

// 驼峰字符串转横线字符串
export function camelToDashLine (camelStr) {
  return camelStr
    .replace(/[A-Z]/g, function (s) {
      return ' ' + s.toLowerCase()
    })
    .trim()
    .replace(/\s+/g, '-')
}

export const getDateString = (dateString, AddDayCount) => {
  if (isMac | isiOS) {
    dateString = dateString.replace(/-/g, '/')
  }

  var dd = new Date(dateString)

  if (AddDayCount) {
    dd.setUTCDate(dd.getUTCDate() + AddDayCount) // 获取AddDayCount天后的日期
  }

  var y = dd.getUTCFullYear()
  var m = dd.getUTCMonth() + 1 // 获取当前月份的日期
  var d = dd.getUTCDate()
  m = m < 10 ? '0' + m : m
  d = d < 10 ? '0' + d : d
  return y + '-' + m + '-' + d
}

export const isMac = () => {
  return /Mac/i.test(navigator.userAgent)
}

export const isiOS = () => {
  return /iPhone|iPod|iPad/i.test(navigator.userAgent)
}

export function getUTCFormattedDate (timestampSecs) {
  const date = new Date(timestampSecs * 1000)
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  const hours =
    date.getUTCHours() > 9 ? date.getUTCHours() : '0' + date.getUTCHours()
  const minutes =
    date.getUTCMinutes() > 9
      ? date.getUTCMinutes()
      : '0' + date.getUTCMinutes()
  const seconds =
    date.getUTCSeconds() > 9
      ? date.getUTCSeconds()
      : '0' + date.getUTCSeconds()
  return `${weekdays[date.getUTCDay()]}, ${date.getUTCDate()} ${
    months[date.getUTCMonth()]
  } ${date.getUTCFullYear()} ${hours}:${minutes}:${seconds} +UTC`
}

export function getDIDNodeHash (fullName) {
  return namehash.hash(fullName)
}

export function getDivImage (divElement) {
  const imgUrl = window
    .getComputedStyle(divElement, null)
    .getPropertyValue('background-image')
  return imgUrl.substring(imgUrl.indexOf('("') + 2, imgUrl.lastIndexOf('")'))
}

export function isMobile () {
  const userAgent = window.navigator.userAgent
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )
  return isMobile
}

export function formatEther (wei = '0') {
  const amountsEther = utils.formatEther(wei)
  return amountsEther
}

export function copy (data) {
  if (!data) return
  const inputElement = document.createElement('input')
  inputElement.value = data
  document.body.appendChild(inputElement)
  inputElement.select()

  if (document.execCommand('Copy')) {
    message.success('Copied !')
  } else {
    message.error('Copy failed !')
  }
  document.body.removeChild(inputElement)
}

export function getTxDetails (chainId, txHash) {
  switch (chainId) {
    case '0x89':
      window.open(`https://polygonscan.com/tx/${txHash}`)
      break
    case '0x13881':
      window.open(`https://mumbai.polygonscan.com/tx/${txHash}`)
      break
    case '0x1':
      window.open(`https://etherscan.io/tx/${txHash}`)
      break
    case '0x5':
      window.open(`https://goerli.etherscan.io/tx/${txHash}`)
      break
    case '0xaa36a7':
      window.open(`https://sepolia.etherscan.io/tx/${txHash}`)
      break
  }
}

export function getAddrDetails (chainId, addr) {
  switch (chainId) {
    case '0x89':
      window.open(`https://polygonscan.com/address/${addr}`)
      break
    case '0x13881':
      window.open(`https://mumbai.polygonscan.com/address/${addr}`)
      break
  }
}

export function errorHandler (err) {
  // TODO
  // if (window.localStorage.getItem('walletconnect')) {
  //   message.error(err.toString())
  //   return
  // }
  switch (err.code) {
    case 4001:
      message.error(err.message, 5)
      break
    case 'ACTION_REJECTED':
      message.error('User rejected transaction.', 5)
      break
    case 'INSUFFICIENT_FUNDS':
      message.error('Insufficient funds for intrinsic transaction cost.', 5)
      break
    case -32002:
      message.info('Request already pending, please view metamask.', 5)
      break
    case 'UNPREDICTABLE_GAS_LIMIT':
      // message.error(err.error.message)
      message.error(err.reason, 5)
      break
    case 4100:
      message.error(err.message, 5)
      break
    case -32602:
      message.error(err.message, 5)
      break
    case -32603:
      // RPC error
      if (err.data.code === -32000) {
        const msg = err.data.message
        message.error(msg.slice(msg.indexOf(':') + 1, msg.lastIndexOf(':'), 5))
        return
      }

      if (err.data.code === -32005) {
        const msg = err.data.details
        message.error(msg, 5)
        return
      }
      message.error(err.message, 5)
      break
    case 'CALL_EXCEPTION':
      message.error('Call revert exception.', 5)
      break
    case 'UNSUPPORTED_OPERATION':
      message.error(
        'Unsupported operation, try again after refreshing the page.',
        5
      )
      console.error('Error:', err)
      break
    default:
      console.error('Error:', err)
      console.error('Error code:', err.code)
      break
  }
}

export async function addSupportedChain (switchError) {
  let errorCode

  if (isMobile()) {
    errorCode = switchError.data.originalError.code
  } else {
    errorCode = switchError.code
  }

  if (errorCode === 4902) {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [getChainConfig()]
      })

      return true
    } catch (err) {
      errorHandler(err)
      // handle "add" error
    }
  }
  return false
}

export async function changeChain (obj) {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: obj.chainID }]
    })
  } catch (switchError) {
    addSupportedChain(switchError)

    // handle other "switch" errors
    // This error code indicates that the chain has not been added to MetaMask.
    //   {
    //   chainId: '0x89',
    //   chainName: 'Polygon',
    //   nativeCurrency: {
    //     name: 'Polygon',
    //     symbol: 'MATIC',
    //     decimals: 18
    //   },
    //   rpcUrls: ['https://polygon-rpc.com/'],
    //   blockExplorerUrls: ['https://polygonscan.com/']
    // }
    // {
    //   chainId: '0x38',
    //   chainName: 'Binance Smart Chain Mainnet',
    //   nativeCurrency: {
    //     name: 'Binance Coin',
    //     symbol: 'BNB',
    //     decimals: 18
    //   },
    //   rpcUrls: ['https://bsc-dataseed1.binance.org'],
    //   blockExplorerUrls: ['https://bscscan.com']
    // }
  }
}

export function addFieldChain () {
  window.ethereum
    .request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x38',
          chainName: 'Field Chain Mainnet',
          nativeCurrency: {
            name: 'Field Coin',
            symbol: 'FC',
            decimals: 18
          },
          rpcUrls: ['https://bsc-dataseed1.binance.org'],
          // rpcUrls: ['https://test-connect.sbtauth.io'],
          blockExplorerUrls: ['https://bscscan.com']
        }
      ]
    })
    .catch((err) => {
      errorHandler(err)
    })
}

function getChainConfig () {
  const chainId = process.env.VUE_APP_SUPPORT_CHAIN_ID

  switch (chainId) {
    case '0x89':
      return {
        chainId: '0x89',
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
          name: 'Polygon',
          symbol: 'MATIC',
          decimals: 18
        },
        rpcUrls: ['https://polygon-rpc.com/'],
        blockExplorerUrls: ['https://polygonscan.com/']
      }
    case '0x13881':
      return {
        chainId: '0x13881',
        chainName: 'Mumbai Testnet',
        nativeCurrency: {
          name: 'Mumbai',
          symbol: 'MATIC',
          decimals: 18
        },
        rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
        // rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
        blockExplorerUrls: ['https://mumbai.polygonscan.com']
      }
  }
}

export function formatTokenId (tokenId, flag) {
  if (!tokenId) return
  if (!flag) {
    return BigNumber.from(tokenId).toString()
  } else {
    return BigNumber.from(tokenId).toHexString()
  }
}

export async function createWalletConnect () {
  let provider
  const polygon = 'https://rpc-mainnet.maticvigil.com'
  const infuraId = process.env.VUE_APP_INFURA_API_KEY
  // const polygon = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.VUE_APP_POLYGON_ALCHEMY_API_KEY_2}`

  const customRpc = {
    137: polygon
    // ...
  }

  // Create WalletConnect Provider
  const walletConnectProvider = new WalletConnectProvider({
    rpc: customRpc,
    infuraId: infuraId // support ethereum mainnet and testnet
    // qrcode: true, // default true
  })

  //  Enable session (triggers QR Code modal)
  try {
    await walletConnectProvider.enable()

    if (window.localStorage.getItem('user')) {
      const user = JSON.parse(window.localStorage.getItem('user'))
      window.localStorage.removeItem(window.localStorage.getItem(user.address))
      window.localStorage.removeItem('user')
    }

    window.walletConnectWeb3Provider = walletConnectProvider
    provider = new providers.Web3Provider(window.walletConnectWeb3Provider)

    console.log('walletConnectWeb3Provider:', window.walletConnectWeb3Provider)
  } catch (err) {
    console.log(err)
  }
  return provider
}

export function createQRCode (data) {
  let dataUrl

  const opts = {
    errorCorrectionLevel: 'H', // H Q M L
    type: 'image/png',
    quality: 1,
    margin: 1,
    color: {
      // dark: '#010599FF'
      // light: '#FFBF60FF'
      // light: '#EBEDFF'
    }
  }

  QRCode.toDataURL(data, opts, (err, url) => {
    if (err) {
      dataUrl = process.env.VUE_APP_APP_URL
      console.log('toDataURL err:', err)
    } else {
      dataUrl = url
      console.log('QRCode', url)
    }
  })
  return dataUrl
}

export function getKeys (infoArray) {
  const keys = []
  for (const value of infoArray) {
    keys.push(value.key)
  }

  return keys
}

export function getNodeFullName (hash, nodeName, flagStr) {
  const nodeNameSuffix = getTopDomain(hash)
  if (flagStr) {
    // 下拉列表中选中的DID
    if (nodeName.length > 20) {
      return nodeName.slice(0, 20) + '*' + nodeNameSuffix
    }

    return nodeName + nodeNameSuffix
  } else {
    // 下拉列表中展示的DID
    if (nodeName.length > 64) {
      return nodeName.slice(0, 64) + '*' + nodeNameSuffix
    }

    return nodeName + nodeNameSuffix
  }
}

export function networkErrorHandler (err) {
  switch (err.code) {
    case 'ETIMEDOUT':
      message.error(
        'Network connection timed out, Please check your network.',
        8
      )
      break
    case 'ERR_NETWORK':
      message.error(
        'Network connection occurred error, Please try again later.',
        8
      )
      break
    default:
      console.error('Axios error:', err)
      console.error('Axios error code:', err.code)
      break
  }
}

export function getTopDomain (hash) {
  // const ETH = '0x93cdeb708b7545dc668eb9280176169d1c33cfd8ed6f04690a0bcc88a93fc4ae'
  // const DID = '0x8a74fc6994ef0554dd9cc95c3391f9cd66152031a0c1feacb835e3890805af5f'
  // const DAO = '0xb5f2bbf81da581299d4ff7af60560c0ac854196f5227328d2d0c2bb0df33e553'
  // const NFT = '0xb75cf4f3d8bc3deb317ed5216d898899d5cc6a783f65f6768eb9bcb89428670d'
  // const FREE = '0x5e8583a43e6021bb6c77d2b9d3b3062ff9495d42c4bca82d6e494d591f6e7492'
  // const BOOL = '0x40ac243157e8ee9e1d3ebb43a6efa84aa344e17e5705664d894a8077933e41cf'
  // const META = '0x033549da90d902eebcededec7286e6a5f4e7b23484d4b06c20bd6ed60e05d4ef'
  // const TEST = '0x04f740db81dc36c853ab4205bddd785f46e79ccedca351fc6dfcbd8cc9a33dd6'
  // const ROOT = '0x0000000000000000000000000000000000000000000000000000000000000000'

  const iHash = process.env.VUE_APP_I_NODE
  const oHash = process.env.VUE_APP_O_NODE

  switch (hash) {
    case iHash:
      return '.i'
    case oHash:
      return '.o'
    default:
      return ''
  }
}
