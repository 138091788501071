import { TreeDBABI } from '@/constants/TreeDB.json'
import { EditorABI } from '@/constants/Editor.json'
import { ResolverABI } from '@/constants/Resolver.json'
import { MultiCallABI } from '@/constants/MultiCall.json'

import { QueryFilterABI } from '@/constants/QueryFilter.json'
import { ArtsDAOAirdropABI } from '@/constants/ArtsDAOAirdrop.json'
import { AirdropABI } from '@/constants/Airdrop.json'
import { MarketABI } from '@/constants/Market.json'
import { RegistrarABI } from '@/constants/Registrar.json'
import { ValueMiningABI } from '@/constants/ValueMining.json'
import { providers, Contract } from 'ethers'

const QueryFilterAddr = process.env.VUE_APP_QUERYFILTER_ADDR
const BufferDBAddr = process.env.VUE_APP_BUFFERDB_ADDR
const TreeDBAddr = process.env.VUE_APP_TREEDB_ADDR
const EditorAddr = process.env.VUE_APP_EDITOR_ADDR
const ResolverAddr = process.env.VUE_APP_RESOLVER_ADDR
const MultiCallAddr = process.env.VUE_APP_MULTICALL_ADDR

const AirdropAddr = process.env.VUE_APP_AIRDROP_ADDR
const ArtsDAOAirdropAddr = process.env.VUE_APP_ARTSDAO_AIRDROP_ADDR
const MarketAddr = process.env.VUE_APP_MARKET_ADDR
const RegistrarAddr = process.env.VUE_APP_REGISTRAR_ADDR
const ValueMiningAddr = process.env.VUE_APP_VALUEMINING_ADDR

const createContract = (provider, address, abi) => {
  let ethersProvider, signer, apiKey

  if (provider) {
    signer = provider
    console.log('currentWeb3Provider:', window.walletConnectWeb3Provider)

    if (window.walletConnectWeb3Provider) {
      // WalletConnect
      ethersProvider = new providers.Web3Provider(
        window.walletConnectWeb3Provider
      )
    } else {
      // Metamask
      ethersProvider = new providers.Web3Provider(window.ethereum)
    }
  } else {
    // Polygon mainnet
    if (process.env.VUE_APP_SUPPORT_CHAIN_NAME === 'Polygon') {
      apiKey = process.env.VUE_APP_POLYGON_ALCHEMY_API_KEY
      ethersProvider = new providers.AlchemyProvider('matic', apiKey)
    } else {
      // Polygon testnet
      apiKey = process.env.VUE_APP_MUMBAI_ALCHEMY_API_KEY
      ethersProvider = new providers.AlchemyProvider('maticmum', apiKey)
    }
  }

  const contract = new Contract(
    address,
    abi,
    signer ? ethersProvider.getSigner() : ethersProvider
  )
  return contract
}

const contractInterface = {
  multiCall: (provider) => createContract(provider, MultiCallAddr, MultiCallABI),
  editor: (provider) => createContract(provider, EditorAddr, EditorABI),
  resolver: (provider) => createContract(provider, ResolverAddr, ResolverABI),
  bufferDB: (provider) => createContract(provider, BufferDBAddr, TreeDBABI),
  treeDB: (provider) => createContract(provider, TreeDBAddr, TreeDBABI),
  market: (provider) => createContract(provider, MarketAddr, MarketABI),
  registrar: (provider) => createContract(provider, RegistrarAddr, RegistrarABI),
  valueMining: (provider) => createContract(provider, ValueMiningAddr, ValueMiningABI),
  airdrop: (provider) => createContract(provider, AirdropAddr, AirdropABI),
  artsDAOAirdrop: (provider) => createContract(provider, ArtsDAOAirdropAddr, ArtsDAOAirdropABI),
  queryFilter: (provider) => createContract(provider, QueryFilterAddr, QueryFilterABI)
}

export default contractInterface
