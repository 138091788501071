const install = (Vue, vm) => {
  // Get请求
  const get = (url, params, headers) => {
    return vm.$axios({
      method: 'get',
      url,
      params,
      headers
    })
  }

  // Post请求
  const post = (url, params, headers = {}, contentType = 'json') => {
    const options = {
      method: 'post',
      url,
      data: params,
      headers
    }

    // 表单数据序列化
    if (contentType === 'form') {
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

      options.transformRequest = [function (data) {
        let ret = ''
        for (const it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }]
    }

    return vm.$axios(options)
  }

  const api = {}

  // api.getDidNodeListByAddress = (querys) => get('/didNode/getDidNodeListByAddress', querys)
  api.getDidNodeListByAddress = (value) => get('/didNode/getDidNodeListByAddress/' + value)
  api.getLatestDividend = (params = {}) => post('/didNode/getLatestDivident', params)
  api.checkinForDidNode = (params = {}) => post('/didNode/userCheckin', params)
  api.getDividentInfo = (params = {}) => post('/api/getDividentInfo', params)

  api.getOrderInfo = (query) => get('/order/getOrderInfo', query)
  api.createOrder = (params = {}) => post('/order/createOrder', params)
  api.getOrderList = (params = {}) => post('/order/orderList', params)
  api.getNftHistoryData = (params = {}) => post('/order/nftHistoryData', params)

  // api.getDidNodeListByAddress = (value) => get('https://goerli.diddao.io/api/didNode/getDidNodeListByAddress/' + value)
  // api.getLatestDividend = (params = {}) => post('https://goerli.diddao.io/api/didNode/getLatestDivident', params)
  // api.checkinForDidNode = (params = {}) => post('https://goerli.diddao.io/api/didNode/userCheckin', params)
  // api.getDividentInfo = (params = {}) => post('https://goerli.diddao.io/api/api/getDividentInfo', params)

  // api.getOrderInfo = (query) => get('http://8.136.211.11/api/order/getOrderInfo', query)
  // api.createOrder = (params = {}) => post('http://8.136.211.11/api/order/createOrder', params)
  // api.getOrderList = (params = {}) => post('http://8.136.211.11/api/order/orderList', params)

  // 挂载至Vue原型，实例对象可使用$api进行访问
  Vue.prototype.$api = api
}

export default {
  install
}
