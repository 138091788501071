// 文档链接 https://1x.antdv.com/docs/vue/introduce-cn/

import Vue from 'vue'

import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Icon,
  Input,
  Modal,
  Menu,
  message,
  Popover,
  Result,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
  Pagination,
  Carousel,
  Tabs,
  Popconfirm,
  Drawer
} from 'ant-design-vue'

Vue.use(Alert)
Vue.use(Breadcrumb)
Vue.use(Button)
Vue.use(Col)
Vue.use(Dropdown)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Modal)
Vue.use(Menu)
Vue.use(Popover)
Vue.use(Result)
Vue.use(Row)
Vue.use(Select)
Vue.use(Spin)
Vue.use(Tag)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Carousel)
Vue.use(Tabs)
Vue.use(Popconfirm)
Vue.use(Drawer)

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$success = Modal.success
Vue.prototype.$warning = Modal.warning
Vue.prototype.$error = Modal.error
Vue.prototype.$info = Modal.info

message.config({
  top: '40px',
  // duration: 2,
  maxCount: 2
})

Vue.prototype.$message = message
export { Vue }
