import axios from 'axios'

const install = (Vue, vm) => {
  const instance = axios.create({
    // baseURL: process.env.VUE_APP_API_BASE, (跨域代理无需设置)
    baseURL: process.env.VUE_APP_API_BASE,
    timeout: 15000,
    transitional: {
      clarifyTimeoutError: true
    },
    // validateStatus (status) {
    // 默认2开头(resolve), 其他(reject)
    // return status >= 200 && status < 400
    // return true 防止因状态码抛出异常
    // },

    // withCredentials: true, // 默认false，同域忽略，跨域（允许跨域，指定url）
    headers: { 'Content-Type': 'application/json' }
  })

  // 请求拦截器
  instance.interceptors.request.use(
    (config) => {
      // ...to do sth
      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  // 响应拦截器
  instance.interceptors.response.use(
    (response) => {
      // ...to do sth
      return response.data
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  Vue.prototype.$axios = instance
}

export default {
  install
}
